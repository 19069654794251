






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import Values from "./partials/Values.vue";
import moment from "moment";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      month: parseInt(this.$route.params.month, 10),
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            stt: {
              text: "STT",
              options: {
                index: true,
              },
            },
            id: {
              text: "ID",
              hidden: true,
            },
            user: {
              text: "User",
              options: {
                subProp: "user.name",
                label: true,
                sortBy: "userId",
              },
            },
            note: {
              text: "Ghi chú",
              sortable: true,
            },
            total: {
              text: "Tổng lương",
              options: {
                filter: "currency",
              },
            },
            revenue: {
              text: "Doanh thu KPI (DS dự kiến + ds test- ds Return)",
              options: {
                preTransform(_, item) {
                  return (
                    (item.parserVariableValues?.DSDK || 0) +
                    (item.parserVariableValues?.RD_DSDK || 0) -
                    (item.parserVariableValues?.RETURN_DSDK || 0)
                  );
                },
                filter: "currency",
              },
            },
            mktCost: {
              text: "CPQC",
              options: {
                preTransform(_, item) {
                  return item.parserVariableValues?.CPQC;
                },
                filter: "currency",
              },
            },
            variableL: {
              text: "Hệ số thưởng L",
              options: {
                preTransform(_, item) {
                  return item.parserVariableValues?.LA;
                },
                filter: "number3",
              },
            },
            onlineWorkDay: {
              text: "Số ngày làm việc online",
              sortable: true,
            },
            offlineWorkDay: {
              text: "Số ngày làm việc offline",
              sortable: true,
            },
            qualityRevenue: {
              text: "Doanh số hiệu quả",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            baseSalaryOrigin: {
              text: "Lương cơ bản (gốc)",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            baseSalary: {
              text: "Lương cơ bản (đã tính toán)",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            birthdayBonus: {
              text: "Thưởng sinh nhật",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            returnedSocialInsuranceFee: {
              text: "Trả lại BHXH",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            teamBonus: {
              text: "Thưởng team",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            lunchAllowanceEnabled: {
              text: "Có phụ cấp ăn trưa",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            lunchAllowance: {
              text: "Phụ cấp ăn trưa",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            socialInsuranceFee: {
              text: "BHXH",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            timeKeepingFine: {
              text: "Phạt chấm công",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            testBonus: {
              text: "Thưởng test",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            returnedParkingFee: {
              text: "Trả lại phí đỗ xe",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            newStaffReferBonus: {
              text: "Thưởng giới thiệu nhân viên mới",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            recruitmentKpiBonus: {
              text: "Thưởng KPI tuyển dụng",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            returnedTvFee: {
              text: "Trả lại tiền thử việc",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            telephoneAllowance: {
              text: "Hỗ trợ điện thoại",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            otherBonus: {
              text: "Thưởng khác",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            fine: {
              text: "Phạt khác",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            excellentStaffBonus: {
              text: "Thưởng cá nhân xuất sắc",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            returnedSampleTransportFee: {
              text: "Trả lại phí vận chuyển hàng sample",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            returnedKocFee: {
              text: "Trả lại phí KOC",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            returnedSampleCost: {
              text: "Trả lại cost hàng sample",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            returnedTravelFee: {
              text: "Trả lại tiền du lịch",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            travelFee: {
              text: "Tiền du lịch",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            otherFee: {
              text: "Phí khác",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            otherSupport: {
              text: "Hỗ trợ khác",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            salaryArrears: {
              text: "Truy thu lương",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            dailyBonus: {
              text: "Thưởng ngày",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            redLineFine: {
              text: "Phạt lằn ranh đỏ",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            kpiBonus: {
              text: "Thưởng KPI",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            topKpiBonus: {
              text: "Thưởng KPI nhân viên xuất sắc",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            ctUpBonus: {
              text: "Thưởng nhân viên lên thử việc",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            leaderCtUpBonus: {
              text: "Thưởng leader lên thử việc",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            bonusArrears: {
              text: "Truy thu nợ",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            teamKpiBonus: {
              text: "Thưởng KPI team",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            leadKpiBonus: {
              text: "Thưởng KPI lead",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            tvKpiBonus: {
              text: "Thưởng KPI thử việc",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            otherKpiBonus: {
              text: "Thưởng KPI khác",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            leadKpiFine: {
              text: "Phạt KPI lead",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            ctKpiFine: {
              text: "Phạt KPI chính thức + phó nhóm",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
              hidden: true,
            },
            action: {},
          },
          topActionButtons: {
            import: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Import",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Import" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Import",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const { month } = dataTable.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await coreApiClient.call(
                              "salaryUserMonthInfos",
                              "import",
                              {
                                month: moment(month).startOf("month").valueOf(),
                              },
                              data
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                              dataTable.refresh();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("salaryUserMonthInfos", `@/export`, findAllOptions);
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              // edit: userManager.checkRole(["admin", "kt"]) && {
              //   target: {
              //     dialog: {
              //       ext: {
              //         subTitleMaker(item) {
              //           return item.user?.name;
              //         },
              //       },
              //     },
              //   },
              // },
              values: {
                content: {
                  icon: "mdi-view-list-outline",
                },
                target: {
                  tooltip: { content: { text: "Tham số tính toán" } },
                  dialog: {
                    attrs: {
                      width: "900px",
                    },
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Tham số tính toán",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.user.email;
                        },
                      },
                      content: {
                        type: Values,
                        attrs: {},
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          console.log(item);
                          attrs.item = item;
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            const { month } = this.context();
            options.filters.push({
              key: "month",
              operator: "equal_to",
              value: moment(month).startOf("month").valueOf(),
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("salaryUserMonthInfos", "findAll", options);
              return [items, count];
            },
            async update(item) {
              return await coreApiClient.call(
                "salaryUserMonthInfos",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
          },
          default: {
            defaultInputs: {
              note: {
                type: "textLarge",
                attrs: {
                  label: "Ghi chú",
                },
              },
              total: {
                type: "number",
                attrs: {
                  label: "Tổng lương",
                },
              },
              onlineWorkDay: {
                type: "number",
                attrs: {
                  label: "Số ngày làm việc online",
                },
              },
              offlineWorkDay: {
                type: "number",
                attrs: {
                  label: "Số ngày làm việc offline",
                },
              },
              baseSalaryOrigin: {
                type: "number",
                attrs: {
                  label: "Lương cơ bản (gốc)",
                },
              },
              baseSalary: {
                type: "number",
                attrs: {
                  label: "Lương cơ bản (đã tính toán)",
                },
              },
              birthdayBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng sinh nhật",
                },
              },
              returnedSocialInsuranceFee: {
                type: "number",
                attrs: {
                  label: "Trả lại BHXH",
                },
              },
              teamBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng team",
                },
              },
              lunchAllowanceEnabled: {
                type: "boolean",
                attrs: {
                  label: "Có phụ cấp ăn trưa",
                },
              },
              lunchAllowance: {
                type: "number",
                attrs: {
                  label: "Phụ cấp ăn trưa",
                },
              },
              socialInsuranceFee: {
                type: "number",
                attrs: {
                  label: "BHXH",
                },
              },
              timeKeepingFine: {
                type: "number",
                attrs: {
                  label: "Phạt chấm công",
                },
              },
              testBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng test",
                },
              },
              returnedParkingFee: {
                type: "number",
                attrs: {
                  label: "Trả lại phí đỗ xe",
                },
              },
              newStaffReferBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng giới thiệu nhân viên mới",
                },
              },
              recruitmentKpiBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng KPI tuyển dụng",
                },
              },
              returnedTvFee: {
                type: "number",
                attrs: {
                  label: "Trả lại tiền thử việc",
                },
              },
              telephoneAllowance: {
                type: "number",
                attrs: {
                  label: "Hỗ trợ điện thoại",
                },
              },
              otherBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng khác",
                },
              },
              fine: {
                type: "number",
                attrs: {
                  label: "Phạt khác",
                },
              },
              excellentStaffBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng cá nhân xuất sắc",
                },
              },
              returnedSampleTransportFee: {
                type: "number",
                attrs: {
                  label: "Trả lại phí vận chuyển hàng sample",
                },
              },
              returnedKocFee: {
                type: "number",
                attrs: {
                  label: "Trả lại phí KOC",
                },
              },
              returnedSampleCost: {
                type: "number",
                attrs: {
                  label: "Trả lại cost hàng sample",
                },
              },
              returnedTravelFee: {
                type: "number",
                attrs: {
                  label: "Trả lại tiền du lịch",
                },
              },
              travelFee: {
                type: "number",
                attrs: {
                  label: "Tiền du lịch",
                },
              },
              otherFee: {
                type: "number",
                attrs: {
                  label: "Phí khác",
                },
              },
              otherSupport: {
                type: "number",
                attrs: {
                  label: "Hỗ trợ khác",
                },
              },
              salaryArrears: {
                type: "number",
                attrs: {
                  label: "Truy thu lương",
                },
              },
              dailyBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng ngày",
                },
              },
              redLineFine: {
                type: "number",
                attrs: {
                  label: "Phạt lằn ranh đỏ",
                },
              },
              kpiBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng KPI",
                },
              },
              topKpiBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng KPI nhân viên xuất sắc",
                },
              },
              ctUpBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng nhân viên lên thử việc",
                },
              },
              leaderCtUpBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng leader lên thử việc",
                },
              },
              bonusArrears: {
                type: "number",
                attrs: {
                  label: "Truy thu thưởng",
                },
              },
              teamKpiBonus: {
                type: "number",
                attrs: {
                  label: "Thưởng KPI team",
                },
              },
            },
            editForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: [
                  "note",
                  "total",
                  "onlineWorkDay",
                  "offlineWorkDay",
                  "baseSalaryOrigin",
                  "baseSalary",
                  "birthdayBonus",
                  "returnedSocialInsuranceFee",
                  "teamBonus",
                  "lunchAllowanceEnabled",
                  "lunchAllowance",
                  "socialInsuranceFee",
                  "timeKeepingFine",
                  "testBonus",
                  "returnedParkingFee",
                  "newStaffReferBonus",
                  "recruitmentKpiBonus",
                  "returnedTvFee",
                  "telephoneAllowance",
                  "otherBonus",
                  "fine",
                  "excellentStaffBonus",
                  "returnedSampleTransportFee",
                  "returnedKocFee",
                  "returnedSampleCost",
                  "returnedTravelFee",
                  "travelFee",
                  "otherFee",
                  "otherSupport",
                  "salaryArrears",
                  "dailyBonus",
                  "redLineFine",
                  "kpiBonus",
                  "topKpiBonus",
                  "ctUpBonus",
                  "leaderCtUpBonus",
                  "bonusArrears",
                  "teamKpiBonus",
                ],
              },
            },
          },
        },
      },
      tableContainer: new DataContainer(),
    };
  },
});
